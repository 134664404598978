<template>
  <page-view>
    <div class="invite_friend">
      <div class="invite_title">邀请好友注册，立享佣金奖励</div>
      <div class="invite_link">
        我发现一个电商人必备的礼品代发网站，注册网址是
        {{ invitekey }}
      </div>
      <div class="invite_link_btn" @click="copyInviteKey">复制网址，邀请好友注册</div>
      <div class="invite_total">
        已成功邀请
        <span class="color">{{ account.extension }}人</span>
      </div>
      <div class="invite_step">
        <div class="invite_step_item one">
          <img src="@/assets/invite.png" alt />
          <span>①分享链接给好友</span>
        </div>
        <div class="jiantou"></div>
        <div class="invite_step_item two">
          <img src="@/assets/invited.png" alt />
          <span>②好友注册，并下单成功</span>
        </div>
        <div class="jiantou"></div>
        <div class="invite_step_item three">
          <img src="@/assets/invite.png" alt />
          <span>③ 您将获得佣金奖励</span>
        </div>
        <span class="computed">佣金计算方式=好友支付订单包裹数 * {{ baseData.inviter }}元/单</span>
      </div>
    </div>
    <div class="invitetable_wrapper">
      <a-tabs @change="changeQueryType" :activeKey="activeKey">
        <a-tab-pane key="0" :tab="'我邀请的好友' + '(' + account.extension + ')'"></a-tab-pane>
        <a-tab-pane key="pay" tab="付费"></a-tab-pane>
        <a-tab-pane key="nopay" tab="普通用户"></a-tab-pane>
        <a-input slot="tabBarExtraContent" placeholder="用户ID" class="query_input" v-model.trim="queryParam.uid" />
        <a-input slot="tabBarExtraContent" placeholder="用户名" class="query_input" v-model.trim="queryParam.username" />
        <a-button
          slot="tabBarExtraContent"
          type="primary"
          class="query_btn"
          @click="
            () => {
              $refs.table.refresh(false);
            }
          "
          >查询</a-button
        >
        <a-button slot="tabBarExtraContent" @click="resetQuery">重置</a-button>
      </a-tabs>

      <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource" style="margin:0 24px">
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>

            <span class="uid_fat" @click="copyInfo(record.uid)" @mouseover="handleMouseover(record)" @mouseout="handleMouseout(record)">
              {{ text.substring(0, 4) + '……' + text.substring(text.split('').length - 4) }}
              <span class="uid_child" v-show="record.show">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="pay" slot-scope="text">
          <span v-if="text">付费用户</span>
          <span v-else>普通用户</span>
        </template>
      </s-table>
    </div>
  </page-view>
</template>

<script>
import { accountList, accountGet } from '@api';
import { formatDate } from '@/utils/util';
import { PageView } from '@/layouts';
import { STable } from '@/components';
export default {
  data() {
    return {
      baseData: baseData,
      account: {},
      invitekey: '',
      activeKey: '0',
      queryParam: {},
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },
        {
          title: '用户名',
          dataIndex: 'username'
        },
        {
          title: '推广码',
          dataIndex: 'code'
        },
        {
          title: '用户类型',
          dataIndex: 'pay',
          scopedSlots: { customRender: 'pay' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          customRender: formatDate
        }
      ],
      dataSource: (parameter) => {
        return accountList('inviter', Object.assign(parameter, this.queryParam)).then((result) => {
          if (this.activeKey == '0') {
            result.data.list.forEach((item) => {
              item.show = false;
            });
            return result.data;
          } else if (this.activeKey == 'pay') {
            const data2 = JSON.parse(JSON.stringify(result.data));
            data2.list = data2.list.filter((item) => !item.pay == false);
            data2.list.forEach((item) => (item.show = false));
            return data2;
          } else {
            const data2 = JSON.parse(JSON.stringify(result.data));
            data2.list = data2.list.filter((item) => item.pay == false);
            data2.list.forEach((item) => (item.show = false));
            return data2;
          }
        });
      }
    };
  },
  components: {
    PageView,
    STable
  },
  mounted() {
    this.getAccountInfo();
  },
  methods: {
    // 复制邀请链接
    copyInviteKey() {
      this.copyInfo('我发现一个电商人必备的礼品代发网站，注册网址是' + this.invitekey);
    },
    // 用户信息
    getAccountInfo() {
      accountGet().then((res) => {
        this.account = res.data;
        this.invitekey = 'http://' + baseData.host + '/register?c=' + this.account.code;
      });
    },
    // 切换tab
    changeQueryType(key) {
      this.activeKey = key;
      this.$refs.table.refresh(false);
    },
    // 重置
    resetQuery() {
      this.activeKey = '0';
      this.queryParam = {};
      this.$refs.table.refresh(false);
    },
    handleMouseover(record) {
      record.show = true;
    },
    handleMouseout(record) {
      record.show = false;
    }
  }
};
</script>

<style lang="less" scoped>
.invite_friend {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  padding: 24px 40px;
  margin-bottom: 24px;
  font-family: PingFang SC;

  .invite_title {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 24px;
  }
  .invite_link {
    width: 679px;
    margin-bottom: 24px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 12px;
    color: rgba(0, 0, 0, 0.85);
  }
  .invite_link_btn {
    cursor: pointer;
    margin-bottom: 16px;
    width: 186px;
    height: 32px;
    background: rgba(30, 149, 243, 1);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .invite_total {
    margin-bottom: 40px;
    color: rgba(0, 0, 0, 0.65);
    .color {
      color: #1890ff;
    }
  }
  .invite_step {
    width: 975px;
    height: 105px;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 4px;
    display: flex;
    padding: 24px 0;
    box-sizing: border-box;
    font-size: 12px;
    .invite_step_item {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        margin-bottom: 8px;
      }
      span {
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .jiantou {
      margin-top: 24px;
      width: 20px;
      height: 8px;
      background: url('~@/assets/css_sprites.png') -539px -570px;
    }
    .one {
      margin-left: 32px;
      margin-right: 24px;
    }
    .two {
      margin-left: 56px;
      margin-right: 40px;
    }
    .three {
      margin-left: 40px;
    }
    .computed {
      color: #1e95f3;
      margin-left: 16px;
      margin-top: 40px;
    }
  }
}
.invitetable_wrapper {
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  background-color: #fff;
  padding-bottom: 24px;
  .query_input {
    width: 240px;
    height: 32px;
    margin-right: 12px;
  }
  .query_btn {
    margin-right: 12px;
  }
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
//tab栏
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 16px;
  padding: 16px 12px;
}
/deep/ .ant-tabs {
  margin-right: 24px;
}
/deep/ .ant-tabs-extra-content {
  line-height: 53px;
}
</style>
